<template>
    <div>
        <DatatableV2 :displayTitle="false" :flat="true" :url="apiUrl"/>
    </div>
</template>
<script>
import DatatableV2 from '@/components/DatatableV2'
export default {
    name:"entityTypeComponent",
    components: {DatatableV2},
    data() {
        return {
            apiUrl:"/custom_value/getByType?type=entity_type"
        }
    },
}
</script>