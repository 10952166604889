<template>
  <div>
    <v-container fluid class="pa-6 customFields">
      <v-row>
        <v-col>
          <v-card rounded="lg" class="">
              <v-tabs vertical>
                <v-tab class="text-left" v-if="!packId || packId == 3">
                  {{$t('reasonsFailure')}}
                </v-tab>
                <v-tab class="text-left" v-if="!packId || packId == 3">
                  {{$t('reportReasons')}}
                </v-tab>
                <v-tab v-if="this.$store.state.auth.currentUser.profile.is_admin">
                  {{$t('jobs')}}
                </v-tab>
                <v-tab v-if="this.$store.state.auth.currentUser.profile.is_admin">
                  {{$t('entityTypes')}}
                </v-tab>
                <!-- <v-tab>
                  {{$t('requestsStatus')}}
                </v-tab> -->
                <v-tab>
                  {{$t('univers')}}
                </v-tab>
                <v-tab>
                  {{$t('templateCat')}}
                </v-tab>
                <v-tab>
                  {{$t('interestCat')}}
                </v-tab>
                <v-tab>
                  {{$t('remindCat')}}
                </v-tab>
                <v-tab v-for="customProperty in customProperties" :key="customProperty">
                  {{$t(customProperty)}}
                </v-tab>
                <v-tab v-for="item in customItemProperties" :key="item">
                  {{$t(item+'CustomProperties')}}
                </v-tab>
                <v-tab-item  v-if="!packId || packId == 3">
                  <v-card class="no-shadow">
                    <v-card-text>
                      <reasonComponent/>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item v-if="!packId || packId == 3">
                  <v-card class="no-shadow">
                    <v-card-text>
                      <reportReasonComponent/>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item v-if="this.$store.state.auth.currentUser.profile.is_admin">
                  <v-card class="no-shadow">
                    <v-card-text>
                      <jobComponent/>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item v-if="this.$store.state.auth.currentUser.profile.is_admin">
                  <v-card class="no-shadow">
                    <v-card-text>
                      <entityTypeComponent/>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <!-- <v-tab-item>
                  <v-card class="no-shadow">
                    <v-card-text>
                      <requestStatusComponent/>
                    </v-card-text>
                  </v-card>
                </v-tab-item> -->
                <v-tab-item>
                  <v-card class="no-shadow">
                    <v-card-text>
                      <universComponent/>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card class="no-shadow">
                    <v-card-text>
                      <templateCatComponent/>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card class="no-shadow">
                    <v-card-text>
                      <interestComponent/>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card class="no-shadow">
                    <v-card-text>
                      <remindCatComponent/>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item v-for="customProperty in customProperties" :key="customProperty">
                  <v-card class="no-shadow">
                    <v-card-text>
                      <customPropertiesComponent :type="customProperty"/>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item v-for="item in customItemProperties" :key="item">
                   <v-card class="no-shadow">
                    <v-card-text>
                      <customPropertiesComponent :type="item+'CustomProperties'"/>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import templateCatComponent from '@/modules/administration/administrationComponents/templateCatComponent'
import reasonComponent from '@/modules/administration/administrationComponents/reasonComponent'
import reportReasonComponent from '@/modules/administration/administrationComponents/reportReasonComponent'
import entityTypeComponent from '@/modules/administration/administrationComponents/entityTypeComponent'
import jobComponent from '@/modules/administration/administrationComponents/jobComponent'
import requestStatusComponent from '@/modules/administration/administrationComponents/requestStatusComponent'
import universComponent from '@/modules/administration/administrationComponents/universComponent'
import interestComponent from '@/modules/administration/administrationComponents/interestComponent'
import remindCatComponent from '@/modules/administration/administrationComponents/remindCatComponent'
import customPropertiesComponent from '@/modules/administration/administrationComponents/customPropertiesComponent'
import GenericDataService from '@/services/GenericDataService'
export default {
  name: "AdministrationCustomFields",
  components: {reasonComponent, reportReasonComponent, entityTypeComponent, jobComponent, requestStatusComponent, universComponent,templateCatComponent, interestComponent, remindCatComponent, customPropertiesComponent},
  data() {
    return {
        customProperties: [],
        customItemProperties : []
    };
  },
  created() {
      this.customProperties = ['contactCustomProperties'];
      GenericDataService.getData('/hard_value/getValuesByType?type=items').then((response) => {
        this.customItemProperties = Object.keys(response.data.data)
      })
  },
  methods: {},
  computed: {
    packId(){
        if(this.$store.state.auth.currentUser.accountParameters.packId)
            return this.$store.state.auth.currentUser.accountParameters.packId
        else 
            return false
    },

  },
};
</script>
<style lang="scss">
.customFields {
  .v-card {
    overflow:hidden;
  }
  .v-tab {
    justify-content: flex-start;
  }
  .v-tabs-bar__content {
    padding:20px;
    border-right:1px solid var(--v-lightgrey-base) !important;
  }
  .v-tabs-slider-wrapper {
    display:none;
  }
  .v-tabs-items {
    padding:20px;
  }
}
</style>
