<template>
    <div>
        <DatatableV2 :displayTitle="false" :flat="true" :url="apiUrl"/>
    </div>
</template>
<script>
import DatatableV2 from '@/components/DatatableV2'
export default {
    name:"interestComponent",
    components: {DatatableV2},
    props: ['type'],
    data() {
        return {
            apiUrl:"/custom_value/getByType?type=" + this.type
        }
    },
}
</script>